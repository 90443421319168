import React, { useEffect } from "react";
import { Button, Text } from "react-styledcomponent-template";
import Layout from "../../components/Layout";
import usePlan from "../../api/plan/get";
import { useParams } from "react-router-dom";

const Page = () => {
  const { id } = useParams<{ id: string }>();
  const plan = usePlan();
  useEffect(() => {
    if (!id) return;
    plan.execute(undefined, { id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Layout>
      <Text>{plan.data?.name}</Text>
      <Text>{plan.data?.price}</Text>
      <Text>{plan.data?.description}</Text>
      <Button fullWidth>削除</Button>
    </Layout>
  );
};

export default Page;
