import React from "react";
import { Head, Text, Button } from "react-styledcomponent-template";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";

const Page = () => {
  return (
    <Layout>
      <Head>予約完了</Head>
      <Text>予約が完了しました</Text>
      <Button fullWidth>
        <Link to="/">トップページへ</Link>
      </Button>
    </Layout>
  );
};

export default Page;
