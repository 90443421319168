import { useApiFormData } from "../useApi";
import Place from "../../types/Place";

type DataType = Place;
interface Params {}

const path = "/place/:id";
const method = "get";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
