import React from "react";
import Layout from "../components/Layout";
import {
  Container,
  Button,
  Input,
  Head,
  Label,
} from "react-styledcomponent-template";
import LinkedButton from "../components/LinkedButton";
import styled from "styled-components";

const Page = () => {
  return (
    <Layout>
      <Head>決済</Head>
      <Container bordered rounded fullWidth>
        <Label label="クレジットカード情報" fullWidth>
          <Input fullWidth placeholder="カード番号" />
        </Label>
        <Label label="有効期限" fullWidth>
          <Input fullWidth placeholder="有効期限" />
        </Label>
        <Label label="セキュリティコード" fullWidth>
          <Input fullWidth placeholder="セキュリティコード" />
        </Label>
        <Label label="カード名義人" fullWidth>
          <Input fullWidth placeholder="カード名義人" />
        </Label>

        <LinkedButton fullWidth to={`/payment/done`}>
          決済する
        </LinkedButton>
      </Container>
    </Layout>
  );
};

export default Page;
