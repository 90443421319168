import { useApiFormData } from "../useApi";

interface DataType {}
interface Params {
  email: string;
  password: string;
}

const path = "/auth/login";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
