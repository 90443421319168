import React from "react";
import Layout from "../components/Layout";
import { Head, Text } from "react-styledcomponent-template";
import LinkedButton from "../components/LinkedButton";

const Page = () => {
  return (
    <Layout>
      <Head>決済完了</Head>
      <Text>加入しました</Text>
      <LinkedButton fullWidth to="/">
        TOPへ
      </LinkedButton>
    </Layout>
  );
};

export default Page;
