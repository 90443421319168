import { useApiFormData } from "../useApi";
import Reserve from "../../types/Reserve";

type DataType = Reserve[];
interface Params {
  admin?: boolean;
}

const path = "/reserve";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
