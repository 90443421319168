import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import Company from "./pages/Company";
import Form from "./pages/Form";
import QA from "./pages/QA";
import Privacypolicy from "./pages/Privacypolicy";
import Terms from "./pages/Terms";
import Places from "./pages/Places";
import Place from "./pages/Place";
import Plans from "./pages/Plans";
import Plan from "./pages/Plan";
import Payment from "./pages/Payment";
import PaymentDone from "./pages/PaymentDone";

import Reserves from "./pages/Reserves";
import Reserve from "./pages/Reserve";
import ReserveDone from "./pages/ReserveDone";

import AdminPlans from "./pages/Admin/Plans";
import AdminPlanAdd from "./pages/Admin/PlanAdd";
import AdminPlanEdit from "./pages/Admin/PlanEdit";
import AdminPlaces from "./pages/Admin/Places";
import AdminPlaceAdd from "./pages/Admin/PlaceAdd";
import AdminPlaceEdit from "./pages/Admin/PlaceEdit";
import AdminReservations from "./pages/Admin/Reservations";
import AdminReservation from "./pages/Admin/Reservation";

import Admin from "./pages/Admin/Top";

import Notfound from "./pages/Notfound";

export default function Routers() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/company" element={<Company />} />
        <Route path="/form" element={<Form />} />
        <Route path="/qa" element={<QA />} />
        <Route path="/privacypolicy" element={<Privacypolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/place" element={<Places />} />
        <Route path="/place/:id" element={<Place />} />
        <Route path="/plan" element={<Plans />} />
        <Route path="/plan/:id" element={<Plan />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/payment/done" element={<PaymentDone />} />
        <Route path="/reserve" element={<Reserves />} />
        <Route path="/reserve/:id" element={<Reserve />} />
        <Route path="/reservedone" element={<ReserveDone />} />

        <Route path="/admin" element={<Admin />} />

        <Route path="/admin/plan" element={<AdminPlans />} />
        <Route path="/admin/plan/add" element={<AdminPlanAdd />} />
        <Route path="/admin/plan/:id" element={<AdminPlanEdit />} />
        <Route path="/admin/place" element={<AdminPlaces />} />
        <Route path="/admin/place/add" element={<AdminPlaceAdd />} />
        <Route path="/admin/place/:id" element={<AdminPlaceEdit />} />
        <Route path="/admin/reservation" element={<AdminReservations />} />
        <Route path="/admin/reservation/:id" element={<AdminReservation />} />

        <Route path="*" element={<Notfound />} />
      </Routes>
    </Router>
  );
}
