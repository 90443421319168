import styled from "styled-components";
import { Container, Spacer } from "react-styledcomponent-template";
import Footer from "./Footer";
import Header from "./Header";

const LayoutContainer = styled(Container)`
  width: 100%;
  height: 100%;
  padding: 0;
  gap: 0;
`;
const Content = styled(Container)`
  width: 100%;
  flex: 1 1 auto;
  align-items: start;
  justify-content: start;
  overflow: scroll;
`;

interface Props {
  children: React.ReactNode;
}
const Layout: React.FC<Props> = (p) => {
  return (
    <LayoutContainer>
      <Header />
      <Content>{p.children}</Content>
      <Spacer />
      <Footer />
    </LayoutContainer>
  );
};

export default Layout;
