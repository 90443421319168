import React from "react";
import { Text } from "react-styledcomponent-template";
import Layout from "../components/Layout";
import usePlans from "../api/plan/list";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Card = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 5px;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: var(--primary-text-color);
`;

const LinkedButton = styled(Link)`
  text-decoration: none;
  color: var(--primary-text-color);
  background-color: var(--primary-bg-color);
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  width: 100%;
`;

const Page = () => {
  const plans = usePlans();
  return (
    <Layout>
      {plans.data?.map((plan) => (
        <Card key={plan.id} to={`/plan/${plan.id}`}>
          <Text>{plan.name}</Text>
          <Text>{plan.price}</Text>
          <Text>{plan.description}</Text>
        </Card>
      ))}
    </Layout>
  );
};

export default Page;
