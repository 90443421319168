import React from "react";

interface Props extends React.HTMLAttributes<HTMLOrSVGElement> {}
const Icon: React.FC<Props> = (p) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.6066 4.3934C26.9995 5.78628 28.1044 7.43986 28.8582 9.25975C29.612 11.0796 30 13.0302 30 15C30 16.9698 29.612 18.9204 28.8582 20.7403C28.1044 22.5601 26.9995 24.2137 25.6066 25.6066C24.2137 26.9995 22.5601 28.1044 20.7403 28.8582C18.9204 29.612 16.9698 30 15 30C13.0302 30 11.0796 29.612 9.25975 28.8582C7.43986 28.1044 5.78628 26.9995 4.3934 25.6066C1.58035 22.7936 -2.96403e-08 18.9782 0 15C2.96402e-08 11.0218 1.58035 7.20644 4.3934 4.3934C7.20644 1.58035 11.0218 2.96402e-08 15 0C18.9782 -2.96403e-08 22.7936 1.58035 25.6066 4.3934ZM21.9866 24.3433L5.65506 8.01338C3.97326 10.2604 3.15778 13.0384 3.35814 15.838C3.55851 18.6375 4.76128 21.2711 6.74593 23.2557C8.73059 25.2404 11.3641 26.4432 14.1637 26.6435C16.9633 26.8439 19.7412 26.0284 21.9883 24.3466L21.9866 24.3433ZM23.25 6.75005C25.2334 8.73403 26.4357 11.3661 26.6368 14.1643C26.8379 16.9624 26.0243 19.7394 24.3449 21.9866L8.01171 5.65506C10.2589 3.97491 13.0363 3.1608 15.835 3.36191C18.6336 3.56302 21.2661 4.76587 23.25 6.75005Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Icon;
