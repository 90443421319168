import React, { useEffect } from "react";
import { Text, Button, Head } from "react-styledcomponent-template";
import Layout from "../components/Layout";
import { useParams, useNavigate } from "react-router-dom";
import PrevButton from "../components/PrevButton";
import usePlan from "../api/plan/get";

const Page = () => {
  const { id = "" } = useParams<{ id: string }>();
  const plan = usePlan({ pathParams: { id } });
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    plan.execute(undefined, { id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleJoin = () => {
    if (!id) return;
    console.log("join", id);

    navigate(`/payment`);
  };

  if (!id) return <Layout>loading...</Layout>;
  return (
    <Layout>
      <Head>プラン({plan.data?.name})の詳細</Head>
      <Text>{plan.data?.price}円</Text>
      <Text>{plan.data?.description}</Text>
      <Button fullWidth onClick={handleJoin}>
        加入する
      </Button>
      <PrevButton fullWidth />
    </Layout>
  );
};

export default Page;
