import { format, parseISO } from "date-fns";

const formatFromStringToDate = (
  dateString: string | undefined,
  formatString: string
) => {
  try {
    if (!dateString) throw new Error("dateString is empty");
    const date = parseISO(dateString);
    // console.log(date);
    return format(date, formatString);
  } catch (e) {
    return "";
  }
};

export default formatFromStringToDate;
