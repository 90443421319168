import styled from "styled-components";
import { Link } from "react-router-dom";
import { Spacer } from "react-styledcomponent-template";

const HeaderContainer = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
  width: 100%;
`;
const Logo = styled(Link)`
  color: var(--primary-text-color);
  text-decoration: none;
`;

const Footer: React.FC = () => {
  return (
    <HeaderContainer>
      <Logo to="/">BBQ予約</Logo>
      <Spacer />
      <Logo to="/">BBQ予約</Logo>
    </HeaderContainer>
  );
};

export default Footer;
