import React from "react";
import { Container, Head, Text } from "react-styledcomponent-template";
import Layout from "../components/Layout";
import { styled } from "styled-components";

const QAContainer = styled(Container)`
  width: 100%;
  padding: 0;
  gap: 10px;
`;
const Question = styled(Text)`
  &:before {
    content: "Q. ";
    color: var(--primary-bg-color);
  }
`;
const Answer = styled(Text)`
  &:before {
    content: "A. ";
    color: var(--secondary-bg-color);
  }
`;

const Page = () => {
  const items = [
    { question: "質問1", answer: "回答" },
    { question: "質問2", answer: "回答" },
    { question: "質問3", answer: "回答" },
  ];
  return (
    <Layout>
      <Head>よくある質問</Head>
      {items.map(({ question, answer }) => (
        <QAContainer>
          <Question>{question}</Question>
          <Answer>{answer}</Answer>
        </QAContainer>
      ))}
    </Layout>
  );
};

export default Page;
