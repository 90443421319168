import React from "react";
import { Label, Input, Button } from "react-styledcomponent-template";
import Layout from "../../components/Layout";
import useAddPlan from "../../api/plan/add";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Page = () => {
  const addPlace = useAddPlan();
  const navigate = useNavigate();
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const name = e.currentTarget?.placename?.value;
    const price = e.currentTarget?.price?.value;
    const description = e.currentTarget?.description?.value;

    if (!name)
      return e.currentTarget?.placename?.setCustomValidity?.(
        "名前を入力してください。"
      );
    if (!price)
      return e.currentTarget?.price?.setCustomValidity?.(
        "価格を入力してください。"
      );
    if (!description)
      return e.currentTarget?.description?.setCustomValidity?.(
        "説明を入力してください。"
      );

    const result = addPlace.execute({ name, price, description });

    if (result instanceof Error) return;

    navigate(`/admin/plan`);
  };
  return (
    <Layout>
      <Form onSubmit={handleSubmit}>
        <Label label="名前" fullWidth>
          <Input
            name="placename"
            fullWidth
            onInput={(e) => e.currentTarget?.setCustomValidity?.("")}
          />
        </Label>
        <Label label="説明" fullWidth>
          <Input
            name="description"
            fullWidth
            onInput={(e) => e.currentTarget?.setCustomValidity?.("")}
          />
        </Label>
        <Label label="価格" fullWidth>
          <Input
            type="number"
            fullWidth
            name="price"
            onInput={(e) => e.currentTarget?.setCustomValidity?.("")}
          />
        </Label>
        <Button>追加</Button>
      </Form>
    </Layout>
  );
};

export default Page;
