import React from "react";
import Layout from "../components/Layout";
import { Container } from "react-styledcomponent-template";

const Page = () => {
  return (
    <Layout>
      <Container row>notfound!</Container>
    </Layout>
  );
};

export default Page;
