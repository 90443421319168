import React from "react";
import { Container, Text } from "react-styledcomponent-template";
import ContainerWithImage from "../../components/ContainerWithImage";
import Layout from "../../components/Layout";
import usePlaces from "../../api/place/list";
import styled from "styled-components";

const PlaceText = styled(Container)`
  width: 100%;
  flex: 1 1 auto;
  align-items: start;
  justify-content: space-between;
  gap: 0;
`;

const Page = () => {
  const places = usePlaces();
  return (
    <Layout>
      {places.data?.map((place) => (
        <ContainerWithImage
          src={place.image}
          to={`/admin/place/${place.id}`}
          key={place.id}
        >
          <PlaceText>
            <Text>{place.name}</Text>
            <Text>{place.price}円</Text>
            <Text>{place.description}</Text>
          </PlaceText>
        </ContainerWithImage>
      ))}
    </Layout>
  );
};

export default Page;
