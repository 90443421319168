import React from "react";
import { Container, Text } from "react-styledcomponent-template";
import ContainerWithImage, {
  TextContainer,
} from "../../components/ContainerWithImage";
import Layout from "../../components/Layout";
import useReserves from "../../api/reserve/list";
import formatFromStringToDate from "../../utils/formatFromStringToDate";

const Page = () => {
  const reserves = useReserves({ params: { admin: true } });
  return (
    <Layout>
      {reserves.data?.map((reserve) => (
        <ContainerWithImage
          key={reserve.id}
          src={reserve.place.image || ""}
          to={`/admin/reservation/${reserve.id}`}
        >
          <TextContainer>
            <Text>{reserve.place.name}</Text>
            {/* <Text>{reserve.user.name}</Text> */}
            <Text>{formatFromStringToDate(reserve.date.startAt, "MM/dd")}</Text>
            <Text>{reserve.peopleCount}人</Text>
            <Text>{reserve.place.price}円</Text>
          </TextContainer>
        </ContainerWithImage>
      ))}
    </Layout>
  );
};

export default Page;
