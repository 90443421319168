import React from "react";
import Layout from "../components/Layout";
import { Container, Head } from "react-styledcomponent-template";

const Page = () => {
  return (
    <Layout>
      <Head>会社概要</Head>
      <Container rounded bordered fullWidth>
        <h2>会社名</h2>
        <p>株式会社＿＿＿＿＿</p>
        <h2>代表者</h2>
        <p>＿＿＿＿＿＿＿＿＿</p>
      </Container>
    </Layout>
  );
};

export default Page;
