import React, { useEffect } from "react";
import { Head, Text, Image } from "react-styledcomponent-template";
import Layout from "../components/Layout";
import useReserve from "../api/reserve/get";
import { useParams } from "react-router-dom";
import formatFromStringToDate from "../utils/formatFromStringToDate";
import styled from "styled-components";

const ImageCovered = styled(Image)`
  width: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

const Page = () => {
  const reserve = useReserve();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (!id) return;
    reserve.execute(undefined, { id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Layout>
      <Head>予約</Head>
      <ImageCovered src={reserve.data?.place.image || ""} aspectRatio={0.4} />
      <Text>{reserve.data?.place.name}</Text>
      <Text>
        {formatFromStringToDate(reserve.data?.date?.startAt, "MM/dd HH:mm")} ~{" "}
        {formatFromStringToDate(reserve.data?.date?.endAt, "MM/dd HH:mm")}
      </Text>
    </Layout>
  );
};

export default Page;
