import React from "react";
import Layout from "../components/Layout";
import { Container, Head, Text } from "react-styledcomponent-template";
import styled from "styled-components";
import ContainerWithImage from "../components/ContainerWithImage";
import usePlaces from "../api/place/list";

const PlaceContainer = ContainerWithImage;

const PlaceText = styled(Container)`
  width: 100%;
  flex: 1 1 auto;
  align-items: start;
  justify-content: space-between;
  gap: 0;
`;

const Page = () => {
  const places = usePlaces();

  return (
    <Layout>
      <Head>会場一覧</Head>
      {places.data?.map((p, index) => (
        <PlaceContainer src={p.image} to={`/place/${p.id}`} key={index}>
          <PlaceText>
            <Text>{p.name}</Text>
            <Text>{p.description}</Text>
            <Text>{p.price}円</Text>
          </PlaceText>
        </PlaceContainer>
      ))}
    </Layout>
  );
};

export default Page;
