import { useEffect } from "react";
import { Container } from "react-styledcomponent-template";
import Layout from "../../components/Layout";
import useReserve from "../../api/reserve/get";
import { useParams } from "react-router-dom";
import format from "../../utils/formatFromStringToDate";

const Page = () => {
  const { id } = useParams<{ id: string }>();
  const reserve = useReserve();

  useEffect(() => {
    if (!id) return;
    reserve.execute({ admin: true }, { id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Layout>
      <Container row>{reserve.data?.place.name}</Container>
      <Container row>
        {format(reserve.data?.date.startAt, "MM/dd HH:mm")} ~{" "}
        {format(reserve.data?.date.endAt, "MM/dd HH:mm")}
      </Container>
      <Container row>{reserve.data?.peopleCount}人</Container>
      <Container row>{reserve.data?.place.price}円</Container>
    </Layout>
  );
};

export default Page;
