import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button, ButtonProps } from "react-styledcomponent-template";

const ButtonStyle = styled(Button).attrs({})``;

interface Props extends ButtonProps {
  to: string;
}

const PrevButton: React.FC<Props> = (p) => {
  const navigate = useNavigate();
  const handlePrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    p?.onClick?.(e);
    navigate(p.to);
  };
  return (
    <ButtonStyle {...p} onClick={handlePrev}>
      {p?.children || "戻る"}
    </ButtonStyle>
  );
};

export default PrevButton;
