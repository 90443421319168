import React from "react";

interface Props extends React.HTMLAttributes<HTMLOrSVGElement> {}
const Icon: React.FC<Props> = (p) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      <path
        d="M15 30C18.9782 30 22.7936 28.4196 25.6066 25.6066C28.4196 22.7936 30 18.9782 30 15C30 11.0218 28.4196 7.20644 25.6066 4.3934C22.7936 1.58035 18.9782 0 15 0C11.0218 0 7.20644 1.58035 4.3934 4.3934C1.58035 7.20644 0 11.0218 0 15C0 18.9782 1.58035 22.7936 4.3934 25.6066C7.20644 28.4196 11.0218 30 15 30ZM21.325 13.2L14.45 20.075C14.0984 20.4261 13.6219 20.6234 13.125 20.6234C12.6281 20.6234 12.1516 20.4261 11.8 20.075L9.3 17.575C8.9688 17.2196 8.78849 16.7494 8.79706 16.2637C8.80563 15.7779 9.00241 15.3145 9.34595 14.9709C9.68948 14.6274 10.1529 14.4306 10.6387 14.4221C11.1244 14.4135 11.5946 14.5938 11.95 14.925L13.125 16.1L18.675 10.55C19.0304 10.2188 19.5006 10.0385 19.9863 10.0471C20.4721 10.0556 20.9355 10.2524 21.2791 10.5959C21.6226 10.9395 21.8194 11.4029 21.8279 11.8887C21.8365 12.3744 21.6562 12.8446 21.325 13.2Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Icon;
