import React, { useEffect, useRef } from "react";
import { Button, Input, Label } from "react-styledcomponent-template";
import Layout from "../../components/Layout";
import usePlace from "../../api/place/get";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Page = () => {
  const { id } = useParams<{ id: string }>();
  const place = usePlace();
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (!id) return;
    const getPlace = async (id: string) => {
      const p = await place.execute(undefined, { id });
      if (p instanceof Error) return;
      if (!formRef.current) return;
      formRef.current.placename.value = p.name;
      formRef.current.description.value = p.description;
      formRef.current.price.value = p.price;
    };
    getPlace(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };
  if (place.loading) return <Layout>loading...</Layout>;
  return (
    <Layout>
      <Form onSubmit={handleSubmit} ref={formRef}>
        <Label label="名前" fullWidth>
          <Input
            name="placename"
            fullWidth
            onInput={(e) => e.currentTarget?.setCustomValidity?.("")}
          />
        </Label>
        <Label label="説明" fullWidth>
          <Input
            name="description"
            fullWidth
            onInput={(e) => e.currentTarget?.setCustomValidity?.("")}
          />
        </Label>
        <Label label="価格" fullWidth>
          <Input
            name="price"
            fullWidth
            type="number"
            onInput={(e) => e.currentTarget?.setCustomValidity?.("")}
          />
        </Label>
        <Label label="画像" fullWidth>
          <Input
            name="image"
            fullWidth
            type="file"
            onInput={(e) => e.currentTarget?.setCustomValidity?.("")}
          />
        </Label>
        <Button fullWidth>送信</Button>
      </Form>
    </Layout>
  );
};

export default Page;
