import { useApiFormData } from "../useApi";
import Plan from "../../types/Plan";

type DataType = Plan[];
interface Params {}

const path = "/plan";
const method = "get";
const eagerLoad = true;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
