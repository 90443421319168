import React from "react";
import { Head, Text } from "react-styledcomponent-template";
import Layout from "../components/Layout";

const terms = `利用規約
この利用規約（以下，「本規約」といいます。）は，＿＿＿＿＿（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます
`;

const Page = () => {
  return (
    <Layout>
      <Head>規約</Head>
      <Text>{terms}</Text>
    </Layout>
  );
};

export default Page;
