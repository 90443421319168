import styled from "styled-components";
import { Link } from "react-router-dom";

const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-around;
  align-items: start;
  background-color: var(--primary-bg-color);
  flex-direction: column;
  width: 100%;
`;
const FooterItem = styled(Link)`
  color: var(--primary-text-color);
  text-decoration: none;
  align-items: start;
  justify-content: start;
  text-align: left;
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterItem to="/">TOP</FooterItem>
      <FooterItem to="/qa">よくある質問</FooterItem>
      <FooterItem to="/company">会社概要</FooterItem>
      <FooterItem to="/privacypolicy">プライバシーポリシー</FooterItem>
      <FooterItem to="/terms">規約</FooterItem>
      <FooterItem to="/form">問い合わせ</FooterItem>
    </FooterContainer>
  );
};

export default Footer;
