import styled from "styled-components";
import { Link } from "react-router-dom";
import { Container } from "react-styledcomponent-template";

const ContainerWithImage = styled(Link)<{ src: string }>`
  display: flex;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 200px;
  flex-direction: column;
  flex-basis: 200px;
  flex-grow: 0;
  flex-shrink: 0;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 5px;
  text-decoration: none;
  color: var(--primary-text-color);
  background-color: var(--primary-background-color);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  overflow: hidden;
  &:before {
    content: " ";
    background-image: url(${(p) => p.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100px;
    flex: 0 0 100px;
  }
`;

export const TextContainer = styled(Container)`
  width: 100%;
  flex: 1 1 auto;
  align-items: start;
  justify-content: space-between;
  gap: 0;
`;

export default ContainerWithImage;
