import React from "react";
import { Head, Text } from "react-styledcomponent-template";
import Layout from "../components/Layout";

const terms = `プライバシーポリシー
＿＿＿＿＿＿＿＿（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。`;

const Page = () => {
  return (
    <Layout>
      <Head>プライバシーポリシー</Head>
      <Text>{terms}</Text>
    </Layout>
  );
};

export default Page;
