import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Button, ButtonProps } from "react-styledcomponent-template";

const PrevButtonStyle = styled(Button).attrs({
  color: "info",
})``;

interface Props extends ButtonProps {}

const PrevButton: React.FC<Props> = (p) => {
  const navigate = useNavigate();
  const handlePrev = (e: React.MouseEvent<HTMLButtonElement>) => {
    p?.onClick?.(e);
    navigate(-1);
  };
  return (
    <PrevButtonStyle {...p} onClick={handlePrev}>
      {p?.children || "戻る"}
    </PrevButtonStyle>
  );
};

export default PrevButton;
