import { useApiFormData } from "../useApi";

interface DataType {}
interface Params {
  name: string;
  price: number;
  description: string;
  image: File;
}

const path = "/place";
const method = "post";
const eagerLoad = false;

// eslint-disable-next-line react-hooks/rules-of-hooks
export default useApiFormData<DataType, Params>({
  path,
  method,
  eagerLoad,
});
