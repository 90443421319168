import React from "react";
import { Container } from "react-styledcomponent-template";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import { styled } from "styled-components";

const LinkedContainer = styled(Container).attrs({ row: true, as: Link })``;

const Page = () => {
  return (
    <Layout>
      <LinkedContainer to="/admin/place">会場一覧</LinkedContainer>
      <LinkedContainer to="/admin/place/add">会場追加</LinkedContainer>
      <LinkedContainer to="/admin/plan">プラン一覧</LinkedContainer>
      <LinkedContainer to="/admin/reservation">予約一覧</LinkedContainer>
    </Layout>
  );
};

export default Page;
