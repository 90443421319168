import React from "react";
import { Head, Label, Input, Button } from "react-styledcomponent-template";
import Layout from "../components/Layout";
import styled from "styled-components";

const Form = styled.form`
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Page = () => {
  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("submit");
  };
  return (
    <Layout>
      <Head>お問い合わせ</Head>
      <Form onSubmit={submit}>
        <Label label="氏名" fullWidth>
          <Input fullWidth />
        </Label>
        <Label label="メールアドレス" fullWidth>
          <Input fullWidth />
        </Label>
        <Label label="お問い合わせ内容" fullWidth>
          <Input fullWidth />
        </Label>
        <Button fullWidth>送信</Button>
      </Form>
    </Layout>
  );
};

export default Page;
