import React from "react";
import Layout from "../components/Layout";
import { Head } from "react-styledcomponent-template";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Homelink = styled(Link)``;

const Page = () => {
  return (
    <Layout>
      <Head>ホーム</Head>
      <Homelink to="/place">会場</Homelink>
      <Homelink to="/signin">ログイン</Homelink>
      <Homelink to="/plan">加入</Homelink>
      <Homelink to="/reserve">予約</Homelink>
    </Layout>
  );
};

export default Page;
