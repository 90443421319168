import React, { useEffect } from "react";
import Layout from "../components/Layout";
import {
  Head,
  Text,
  Input,
  Label,
  Container,
  Button,
  Dialog,
} from "react-styledcomponent-template";
import CheckIcon from "../components/IconCheck";
import NGIcon from "../components/IconNG";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import usePlace from "../api/place/get";
import useReserve from "../api/reserve/add";
import formatFromStringToDate from "../utils/formatFromStringToDate";

const ScheduleContainer = styled(Container)`
  width: 100%;
  flex: 0 0 auto;
  overflow: scroll;
  justify-content: start;
  gap: 10px;
  flex-direction: row;
  padding: 0;
`;
const ScheduleItem = styled(Container)<{ active?: boolean }>`
  width: 80px;
  gap: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: #ccc;
  border-radius: 5px;
  background-color: ${(p) => (p.active ? "#ccc" : "transparent")};
`;
const ScheduleDate = styled(Container)`
  width: 100%;
  padding: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
const ScheduleIcon = styled(Container)`
  width: 100%;
  padding: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
`;
const Form = styled.form`
  width: 100%;
  flex: 0 0 auto;
  gap: 20px;
  flex-direction: column;
  display: flex;
  padding: 0;
`;

const Page = () => {
  const [active, setActive] = React.useState<number>(0);
  const [open, setOpen] = React.useState<boolean>(false);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const place = usePlace();
  const reserve = useReserve();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const selectDate = (index: number) => () => {
    setActive(index);
  };
  const selectedDate = place.data?.schedule?.find((_, i) => i === active)?.date;

  const handleReserve = async () => {
    if (!id) return;
    // if (!selectedDate) return;
    const selectedDate2 = new Date(selectedDate || "");
    const place = Number(id);
    const r = await reserve.execute({
      place,
      date: selectedDate2,
      numberOfPeople: 1,
    });
    setOpen(false);
    if (r instanceof Error) return;
    navigate(`/reservedone`);
  };

  useEffect(() => {
    if (!id) return;
    const getPlace = async () => {
      await place.execute(undefined, { id });
    };
    getPlace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Layout>
      <Head>会場詳細</Head>
      <Form onSubmit={handleSubmit}>
        <Text>{place.data?.name}</Text>
        <Text>{place.data?.description}</Text>
        <Text>{place.data?.price}円</Text>
        <Label label="利用人数" fullWidth>
          <Input type="number" fullWidth defaultValue={1} />
        </Label>
        <ScheduleContainer>
          {place.data?.schedule?.map((s, index) => (
            <ScheduleItem
              key={s.id}
              active={index === active}
              onClick={selectDate(index)}
            >
              <ScheduleDate>
                {formatFromStringToDate(s?.date, "MM/dd")}
              </ScheduleDate>
              <ScheduleIcon>
                {s.available ? <CheckIcon /> : <NGIcon />}
              </ScheduleIcon>
            </ScheduleItem>
          ))}
        </ScheduleContainer>
        {reserve.code && reserve.code === 409 && (
          <Text>すでに予約されています</Text>
        )}
        <Button fullWidth>予約する</Button>
      </Form>
      <Dialog open={open} onClose={handleClose}>
        <Head>予約します</Head>
        <Text>
          {place.data?.name}を
          {selectedDate && formatFromStringToDate(selectedDate, "MM/dd")}
          で予約します
        </Text>
        <Button fullWidth onClick={handleReserve} loading={reserve.loading}>
          予約する
        </Button>
        <Button fullWidth onClick={handleClose}>
          閉じる
        </Button>
      </Dialog>
    </Layout>
  );
};

export default Page;
