import React from "react";
import {
  Head,
  Label,
  Input,
  Button,
  Text,
} from "react-styledcomponent-template";
import Layout from "../components/Layout";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useSignin from "../api/auth/signin";

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;
const LinkedText = styled(Text).attrs({ as: Link })``;

const Page = () => {
  const signin = useSignin();
  const navigate = useNavigate();
  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const email = e.currentTarget?.email?.value;
    const password = e.currentTarget?.password?.value;

    if (!email)
      return e.currentTarget?.email?.setCustomValidity?.(
        "メールアドレスを入力してください。"
      );

    if (!password)
      return e.currentTarget?.password?.setCustomValidity?.(
        "パスワードを入力してください"
      );

    const result = await signin.execute({
      email,
      password,
    });
    if (result instanceof Error) return;

    navigate("/");
  };
  return (
    <Layout>
      <Head>ログイン</Head>
      <Form onSubmit={submit}>
        <Label label="メールアドレス" fullWidth>
          <Input
            fullWidth
            name="email"
            onInput={(e) => e.currentTarget?.setCustomValidity("")}
          />
        </Label>
        <Label label="パスワード" fullWidth>
          <Input
            fullWidth
            type="password"
            name="password"
            onInput={(e) => e.currentTarget?.setCustomValidity("")}
          />
        </Label>
        <Button fullWidth loading={signin.loading}>
          ログイン
        </Button>
        {!!signin.error && <Text error>エラーが発生しました</Text>}
        <LinkedText to="/signup">アカウントをお持ちでない方はこちら</LinkedText>
      </Form>
    </Layout>
  );
};

export default Page;
