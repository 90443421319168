import React from "react";
import { Container, Head, Text } from "react-styledcomponent-template";
import Layout from "../components/Layout";
import ContainerWithImage from "../components/ContainerWithImage";
import styled from "styled-components";
import useReserves from "../api/reserve/list";
import formatFromStringToDate from "../utils/formatFromStringToDate";

const PlaceText = styled(Container)`
  width: 100%;
  flex: 1 1 auto;
  align-items: start;
  justify-content: space-between;
  gap: 0;
`;

const Page = () => {
  const reserves = useReserves();

  return (
    <Layout>
      <Head>予約一覧</Head>
      {reserves.data?.map((reserve, index) => (
        <ContainerWithImage
          key={reserve.id}
          src={reserve.place.image}
          to={`/reserve/${reserve.id}`}
        >
          <PlaceText>
            <Text>{reserve.place.name}</Text>
            <Text>{formatFromStringToDate(reserve.date.startAt, "MM/dd")}</Text>
          </PlaceText>
        </ContainerWithImage>
      ))}
    </Layout>
  );
};

export default Page;
